<template>
  <div>
    <SettingsBanner></SettingsBanner>

    <b-container
      v-if="loaded"
      class="mb-5 pb-5 text-light"
      id="artist-dashboard"
    >
      <b-row>
        <b-col>
          <h1 class="font-weight-light dashboard-heading text-center">
            Back Stage Management
          </h1>

          <p class="dashboard-info mb-4 text-center">
            Welcome to Trackd for Artists. This is an overview of your Creator
            Account. Use the menu to access more functions and manage your
            account further.
          </p>
        </b-col>
      </b-row>

      <b-row
        class="
          mt-lg-2 
          mt-xl-5 
          label-text"
      >
        <!-- Inner left column (Account setup steps etc.) -->
        <b-col cols="12" md="6" xl="4" class="">
          <b-row>
            <AccountSetupSteps
              v-bind:account_steps="artist.account_steps"
            ></AccountSetupSteps>

            <DashboardMainMenu v-bind:artsit_data="artist"></DashboardMainMenu>

            <BeingPlayed
              class="d-none d-md-block d-xl-none pt-3"
              v-bind:being_played="artist.being_played"
            ></BeingPlayed>
          </b-row>
        </b-col>

        <!-- Inner right column (Quick stats etc.) -->
        <b-col
          cols="12"
          md="6"
          xl="8"
          class="mt-5 mt-md-0 text-light d-block font-weight-light menu-text"
        >
          <!--<router-link
            :to="{ name: 'EditAccountSettings' }"
            class="text-uppercase text-light d-block font-weight-light menu-text"
          >-->
          <span class="text-uppercase"> Quick Stats</span>

          <!-- <img
                src="../../../assets/arrow-right.svg"
                class="float-right arrow ml-2 full-stats-arrow"
                alt="Right arrow"
              />
            <span class="float-right text-capitalize">  Go to Full Stats</span>
            
          </router-link>-->
          <hr class="bg-light" />

          <b-row class="">
            <!-- Monthly earnings / plays / followers etc. -->
            <b-col cols="12" xl="6">
              <b-row class="">
                <MonthlyEarnings
                  v-bind:chipin="artist.chipin"
                  class="mt-3 mt-md-4"
                ></MonthlyEarnings>

                <MonthlyPlays
                  v-bind:plays_month="artist.plays_month"
                  class="mt-3 mt-md-4"
                ></MonthlyPlays>

                <StatBoxSmall
                  :type="'Followers'"
                  :value="artist.artist_stats.amount"
                  class="mt-3 mt-md-4 c-followers-container"
                ></StatBoxSmall>

                <StatBoxSmall
                  :type="'Song Likes'"
                  :value="artist.song_stats.amount"
                  class="mt-3 mt-md-4 c-song-likes-container"
                ></StatBoxSmall>

                <BeingPlayed
                  class="d-md-none mt-3 mt-md-4"
                  v-bind:being_played="artist.being_played"
                ></BeingPlayed>

                <FanLocation
                  class="d-xl-none mt-3 mt-md-4"
                  v-bind:fan_stats="artist.fan_stats"
                ></FanLocation>

                <ArtistsUsingTrackd
                  class="d-none d-md-block mt-3 mt-md-4"
                  v-bind:artists_using_trackd="artist.artists_using_trackd"
                ></ArtistsUsingTrackd>
              </b-row>
            </b-col>

            <!-- Fans / What's being played etc. -->
            <b-col cols="6" class="d-none d-xl-block">
              <b-row class="">
                <FanLocation
                  v-bind:fan_stats="artist.fan_stats"
                  class="mt-3 mt-md-4"
                ></FanLocation>

                <BeingPlayed
                  class="mt-3 mt-md-4"
                  v-bind:being_played="artist.being_played"
                ></BeingPlayed>

                <!--<MarketingTools></MarketingTools>-->
              </b-row>
            </b-col>
          </b-row>

          <!-- </b-row> -->
        </b-col>

        <b-col cols="12" class="d-xl-none">
          <b-row>
            <!--<MarketingTools></MarketingTools>-->
            <ArtistsUsingTrackd
              class="d-md-none  mt-3 mt-md-4"
              v-bind:artists_using_trackd="artist.artists_using_trackd"
            ></ArtistsUsingTrackd>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
//import AccountHeader from "../../../components/AccountHeader.vue";
import SettingsBanner from "../../../components/settings/Banner.vue";
import AccountSetupSteps from "../../../components/dashboard/account-setup-steps.vue";
import MonthlyEarnings from "../../../components/dashboard/quick-stats/monthly-earnings.vue";
import MonthlyPlays from "../../../components/dashboard/quick-stats/monthly-plays.vue";
import StatBoxSmall from "../../../components/dashboard/quick-stats/stat-box-small.vue";
import FanLocation from "../../../components/dashboard/quick-stats/fan-location.vue";
import BeingPlayed from "../../../components/dashboard/quick-stats/whats-being-played.vue";
import DashboardMainMenu from "../../../components/dashboard/main-menu.vue";
//import MarketingTools from "../../../components/dashboard/marketing-tools.vue";
import ArtistsUsingTrackd from "../../../components/dashboard/artists-using-trackd.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "ArtistDashboard",
  data() {
    return {
      loaded: false,
      profile: null,
      artist: null,
    };
  },
  components: {
    AccountSetupSteps,
    ArtistsUsingTrackd,
    BeingPlayed,
    DashboardMainMenu,
    FanLocation,
    //MarketingTools,
    MonthlyEarnings,
    MonthlyPlays,
    SettingsBanner,
    StatBoxSmall,
  },
  methods: {
    ...mapActions("Account", ["getEditAccountSettings", "dashboard"]),
    ...mapActions("Artist", {
      artistapi: "artist",
      artistwebapi: "artistweb",
    }),
    ...mapMutations(["kochavaEvent"]),
  },
  mounted() {
    if (this.$route.query.k_track) {

      this.kochavaEvent("UpgradeSuccess", {
        package: this.$route.query.k_track,
        username: this.$store.state.username
      })

    }
    this.dashboard()
      .then((res) => {
        this.artist = res;
        this.loaded = true;

      })
      .catch((error) => {
        error;
        this.$router.replace("/error");

      });
  },
};
</script>

<style scoped>
.dashboard-heading {
  font-size: 24px;
  font-weight: 300;
  line-height: 60px;
}

.full-stats-arrow {
  position: relative;
  top: -3px;
}

.dashboard-info {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

@media (min-width: 768px) {
  .full-stats-arrow {
    transform: translateY(35%);
  }
}

@media (min-width: 992px) {
  .dashboard-heading {
    font-size: 36px;
    font-weight: 300;
    line-height: 90px;
  }

  .dashboard-info {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  .menu-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }

  .full-stats-arrow {
    transform: none;
  }
}

@media (min-width: 1200px) {
  .c-followers-container,
  .c-song-likes-container {
    margin-top: 2.2rem !important;
  }

  .dashboard-heading {
    font-size: 36px;
    font-weight: 300;
    line-height: 90px;
  }

  .dashboard-info {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  .menu-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
}
</style>
